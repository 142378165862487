<template>
	<Dialog :dialog="resignDialog">
		<template v-slot:title>Resigned {{ type }}</template>
		<template v-slot:body>
			<v-form
				ref="resignForm"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="onSubmit"
			>
				<v-row class="px-4">
					<v-col md="12" class="py-0">
						<label class="btx-label mt-2 required">Resigned Date</label>
						<DatePicker
							hide-details
							clearable
							:disabled="pageLoading"
							:loading="pageLoading"
							id="purchased-on"
							placeholder="Resigned Date"
							v-model="resigned_date"
							:rules="[vrules.required(resigned_date, 'Resigned Date')]"
							:class="{
								required: !resigned_date,
							}"
						></DatePicker>
					</v-col>
					<v-col md="12" class="py-0">
						<label class="btx-label mt-2 required">Reason</label>
						<TextAreaInput
							hide-details
							:id="`resigned-reason`"
							placeholder="Description"
							v-model="resigned_reason"
							:disabled="disabled"
							:loading="loading"
							:rules="[vrules.required(resigned_reason, 'Reason')]"
							:class="{
								required: !resigned_reason,
							}"
						></TextAreaInput>
					</v-col>
					<v-col md="12" class="py-0">
						<CheckoutListing
							class="mx-4"
							relation-type="member"
							:relation-type-id="memberId"
							title="Issue"
							status="checked-out"
						></CheckoutListing>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn
				class="white--text"
				:loading="disabled"
				:disabled="disabled"
				depressed
				color="green"
				tile
				v-on:click="onSubmit()"
			>
				Yes! Resigned
			</v-btn>
			<v-btn depressed tile :disabled="disabled" v-on:click="$emit('close', true)"> No, Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import TextAreaInput from "@/view/components/TextAreaInput";
import DatePicker from "@/view/components/DatePicker";
import CheckoutListing from "@/view/components/CheckoutListing";

export default {
	name: "activate-template",
	title: "Activate Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		activateUrl: {
			type: String,
			default: null,
		},
		activateText: {
			type: String,
			default: null,
		},
		activateNote: {
			type: String,
			default: null,
		},
		memberId: {
			type: Number,
			default: 0,
		},
		resignDialog: {
			type: Boolean,
			default: false,
		},
		uuid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			resigned_reason: null,
			resigned_date: null,
			disabled: false,
			pageLoading: false,
			formValid: true,
			loading: false,
		};
	},
	components: {
		Dialog,
		CheckoutListing,
		TextAreaInput,
		DatePicker,
	},
	methods: {
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.resignForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.resignForm.validate()) {
				return false;
			}

			this.activateButton = true;
			ApiService.put(`member/${this.uuid}/resigned`, {
				reason: this.resigned_reason,
				resigned_date: this.resigned_date,
			})
				.then(() => {
					this.$emit("success");
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: `Success ! Staff has been resigned.` },
					]);
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.activateButton = false;
				});
		},
	},
	computed: {
		...mapGetters(["errors"]),
	},
};
</script>
