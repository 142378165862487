<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/reservation-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				Permissions
				<v-icon
					class="primary--text px-2"
					v-if="!editFlag"
					v-on:click="
						editFlag = true;
						readonly = false;
					"
					>mdi-pencil</v-icon
				>
				<v-btn
					v-if="editFlag"
					v-on:click="
						editFlag = false;
						readonly = true;
					"
					:disabled="formLoading"
					class="float-right"
					depressed
					tile
				>
					Cancel
				</v-btn>
				<v-btn
					v-if="editFlag"
					:disabled="formLoading"
					:loading="formLoading"
					class="float-right mx-2 custom-bold-button white--text"
					v-on:click="updateUserPermission"
					tile
					depressed
					color="blue darken-4 white--text"
				>
					Update Permissions
				</v-btn>
			</v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 306px)">
			<Permissions
				:permissions="permissions"
				:updatePermissionsList="formPermissionsList"
				v-on:updatePermissionData="updatePermissionData"
				:readonly="readonly"
			></Permissions>
		</div>
	</div>
</template>
<script>
import { toSafeInteger } from "lodash";
import { GetPermission, GetUserPermission, AssignedUserPermission } from "@/core/lib/setting.lib";
import Permissions from "@/view/module/setting/rolePermission/Permission";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	props: {
		uuid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			permissions: [],
			formLoading: false,
			formPermissionsList: [],
			readonly: true,
			editFlag: false,
		};
	},
	methods: {
		updateUserPermission() {
			if (this.formPermissionsList.length == 0) {
				this.$store.commit(SET_ERROR, [
					{ model: true, message: "Please Select Atleast One Permission" },
				]);
				return false;
			}
			this.formLoading = true;
			AssignedUserPermission(this.formPermissionsList, this.uuid)
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Staff assigned permissions successfully" },
					]);
				})
				.catch((error) => {
					this.logError(error);
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.editFlag = false;
					this.readonly = true;
					this.formLoading = false;
				});
		},
		updatePermissionData(data) {
			this.formPermissionsList = data;
		},
		getPermission() {
			const _this = this;
			_this.formLoading = true;
			GetPermission()
				.then((data) => {
					_this.permissions = data || new Array();
					_this.formLoading = false;
					_this.permissionLoading = false;
					_this.userPermission();
				})
				.catch((error) => {
					this.logError(error);
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.formLoading = false;
				});
		},
		userPermission() {
			const _this = this;
			GetUserPermission(this.uuid)
				.then((data) => {
					if (data && data.length > 0) {
						let result = data.map((row) => {
							return toSafeInteger(row.permission_id);
						});
						console.log(result);
						_this.formPermissionsList = result;
					}
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.formLoading = false;
				});
		},
	},
	components: {
		Permissions,
	},
	mounted() {
		this.getPermission();
	},
	beforeDestroy() {},
	computed: {},
};
</script>
